import styled from 'styled-components'
import clipIcon from '../../../../../assets/anexo.svg'
import sendIcon from '../../../../../assets/logo_icon.png'
import {COLORS} from '../../../../../utils/colors'
import {
    KeyboardEvent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import {AssistantApi} from '../../../../../services/AssistantApi.service'
import {HistoryContext} from '../../../../../contexts/history'
import {useLoading} from '../../../../../globalComponents/useLoading'
import Tooltip, {StyledTooltip} from '../../../../../globalComponents/tooltip'
import ArrowUpButton from './arrowUpButton'
import ChatCounter from './charCounter'

const Footer = styled.footer`
    font-size: 12px;
    color: ${COLORS.cinzaEscuro};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
`
const InputContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 700px;
    max-height: 540px;
    max-width: 100%;
    align-self: center;
    border-radius: 8px;
    border: 1px solid ${COLORS.cinzaEscuro};
    padding: 0px 10px;

    & textarea {
        border: transparent;
        padding: 0px 10px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        max-height: 500px;
        margin-top: 15px;
        outline: 0;
    }

    & img {
        padding: 5px;
        cursor: pointer;
    }

    & button {
        background: transparent;
        border: none;
    }
`
const SendButton = styled.button`
    background: transparent;
    position: relative;
    border: none;

    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`
const ClipIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:hover ${StyledTooltip} {
        visibility: visible;
    }
`

export default function Input() {
    const inputRef = useRef<HTMLTextAreaElement | null>(null)
    const [newMessage, setNewMessage] = useState('')
    const [maxLength, setMaxLength] = useState<number>(1500)
    const {Spinner, setIsLoading, isLoading} = useLoading()
    const {
        selected,
        includeMessageAtArray,
        messagesArray,
        assistant,
        getLimits,
        limits,
    } = useContext(HistoryContext)
    const api = useMemo(() => new AssistantApi(), [])
    const assistantApi = useMemo(() => new AssistantApi(), [])

    useEffect(() => scrollDown(), [messagesArray])

    useEffect(() => {
        async function fetchConstraints() {
            try {
                const maxLengthFromApi = await assistantApi.getConstraints()
                setMaxLength(maxLengthFromApi.max_characters)
            } catch (error) {
                console.error('Erro ao obter constraints:', error)
            }
        }
        fetchConstraints()
    }, [assistantApi])

    function scrollDown() {
        document.querySelector('#anchor')?.scrollIntoView({block: 'end'})
    }

    async function sendMessage() {
        setIsLoading(true)
        const thread_id = selected?.guid
        if (!newMessage || !thread_id || !assistant) {
            setIsLoading(false)
            return
        }
        includeMessageAtArray({
            guid: '',
            assistant_id: '',
            created_at: new Date(),
            thread_id: thread_id,
            author_id: 'user',
            content: newMessage,
        })
        setNewMessage('')
        inputRef.current!.style.height = '45px'
        await api
            .sendMessage(
                thread_id,
                newMessage,
                assistant?.guid,
                selected.owner_id
            )
            .then((message) => {
                includeMessageAtArray(message)
                getLimits()
            })
            .catch((error) =>
                includeMessageAtArray({
                    content: 'Erro ao enviar mensagem. Tente novamente.',
                    thread_id: '',
                    assistant_id: 'schief',
                    author_id: '',
                    created_at: new Date(),
                    guid: '',
                })
            )
            .finally(() => {
                setIsLoading(false)
            })
    }

    function autoExpand(event: React.FormEvent<HTMLTextAreaElement>) {
        const textarea = event.target as HTMLTextAreaElement

        textarea.style.height = 'auto'

        const computedStyle = window.getComputedStyle(textarea)
        const paddingTop = parseInt(
            computedStyle.getPropertyValue('padding-top'),
            10
        )
        const paddingBottom = parseInt(
            computedStyle.getPropertyValue('padding-bottom'),
            10
        )

        const contentHeight = textarea.scrollHeight - paddingTop - paddingBottom
        textarea.style.height = `${contentHeight}px`
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            sendMessage()
        }
    }

    useEffect(() => {
        inputRef.current?.focus()
    }, [isLoading])

    return (
        <>
            <InputContainer>
                <ClipIcon>
                    <Tooltip left="0" text="Em desenvolvimento" top="-15px" />
                    <img width="20px" src={clipIcon} />
                </ClipIcon>
                {/* <input type="file" accept=".pdf" /> */}
                <Spinner />
                <textarea
                    autoFocus
                    maxLength={maxLength}
                    id="myTextarea"
                    ref={inputRef}
                    onInput={autoExpand}
                    disabled={isLoading}
                    placeholder={`Faça uma pergunta ao Schief${
                        ': ' + assistant?.name || ''
                    }`}
                    value={newMessage}
                    onChange={(event) => setNewMessage(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <ChatCounter text={newMessage} maxLength={maxLength} />
                <ArrowUpButton />
                <SendButton disabled={isLoading} onClick={sendMessage}>
                    <Tooltip left="0px" top="-15px" text="Enviar" />
                    <img width="30px" src={sendIcon} />
                </SendButton>
            </InputContainer>
            <Footer>
                Schief: a sua inteligência artificial de assistência jurídica
            </Footer>
        </>
    )
}
