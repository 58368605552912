import { SetStateAction, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../../../utils/colors'
import { HistoryContext } from '../../../../../contexts/history'
import ListItem from './ListItem'
import { Button, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { AssistantApi } from '../../../../../services/AssistantApi.service'
import { threadType } from '../../../../../types/thread'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Title = styled.h2`
    font-size: 20px;
    font-weight: 400;
    margin: 15px 0;
    text-align: center;
    color: ${COLORS.cinzaEscuro};
`

const List = styled.ul`
    list-style: none;
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default function History() {
    const { historyArray: contextHistoryArray } = useContext(HistoryContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [historyPage, setHistoryPage] = useState(1);
    const [historyArray, setHistoryArray] = useState<threadType[]>([]);
    const limit = 15;

    useEffect(() => {
        setHistoryArray(contextHistoryArray)
    }, [contextHistoryArray])

    const handleSearchChange = (event: { target: { value: SetStateAction<string> } }) => {
        setSearchTerm(event.target.value)
    }

    const loadMoreItems = async () => {
        const api = new AssistantApi()

        setHistoryPage((prevPage) => prevPage + 1);
        const newThreads = await api.getThreads(limit, historyPage * limit)

        if (newThreads.length > 0) {
            setHistoryArray((prevArray) => [...prevArray, ...newThreads])
        }
    }

    const filteredHistory = historyArray
        .filter((item) => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
        .slice(0, historyPage * limit)

    return (
        <Container>
            <Title>Histórico</Title>
            <TextField
                size="small"
                variant="outlined"
                placeholder="Pesquisar..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{ mb: 2, mx: 2 }}
            />
            <List>
                {filteredHistory?.map((item) => (
                    <ListItem item={item} key={item.guid} />
                ))}
                <Button onClick={loadMoreItems} variant="contained" sx={{ mt: 2 }}>
                    Ver mais
                </Button>
            </List>
        </Container>
    );
}
